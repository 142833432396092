
























import type { PropType } from '@nuxtjs/composition-api';
import VueI18n from 'vue-i18n';
import { defineComponent, computed } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'SummaryItem',
  props: {
    title: {
      type: String as PropType<VueI18n.TranslateResult>,
      required: true,
    },
    subtitle: {
      type: String as PropType<VueI18n.TranslateResult>,
      default: () => '',
    },
    description: {
      type: String as PropType<VueI18n.TranslateResult>,
      required: true,
    },
    isBold: {
      type: Boolean,
      default: false,
    },
    responsive: {
      type: Boolean,
      default: false,
    },
    colon: {
      type: Boolean,
      default: true,
    },
    isDiscount: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const summaryItemTitle = computed(() => `${props.title}${props.colon ? ':' : ''}`);
    return {
      summaryItemTitle,
    };
  },
});
