



































import { defineComponent, computed } from '@nuxtjs/composition-api';
import type { LineItem } from '@vsf-enterprise/commercetools-types';
import type { PropType } from '@nuxtjs/composition-api';
import {
  cartGetters,
} from '@vsf-enterprise/commercetools';
import {
  useCartExtended,
  useExtraGuarantee,
  useExtraGuaranteeOptions,
  useCartSidebarStore,
  useUiState,
  useLineItems,
} from '~/composables';
import ImgView from '~/components/atoms/ImgView/ImgView.vue';
import { getTotalGrossPriceBeforeDiscount } from '~/helpers/cart/getTotalPrices';

export default defineComponent({
  name: 'AddExtraGuarantee',
  components: { ImgView },
  props: {
    product: {
      type: Object as PropType<LineItem>,
      required: true,
    },
  },
  setup (props, { emit }) {
    const {
      addExtraGuaranteeProduct,
    } = useCartExtended();
    const { setIsGuaranteeActionBeingClicked } = useLineItems();
    const { hasOnlyOneOption } = useExtraGuaranteeOptions();
    const { toggleCartSidebar } = useCartSidebarStore();
    const { shouldAddExtraGuarantee, setExtraGuaranteeInfo } = useExtraGuarantee();
    const { toggleExtraGuarantee } = useUiState();
    const shouldDisplayComponent = computed(() => {
      return shouldAddExtraGuarantee(props.product);
    });
    const title = computed(() => cartGetters.getItemName(props.product));

    const handleExtraGuaranteeClick = () => {
      setExtraGuaranteeInfo({
        price: getTotalGrossPriceBeforeDiscount(props.product),
        name: props?.product?.name,
        image: props.product.variant?.images?.[0]?.url,
        sku: props.product.variant.sku,
      });
      if (hasOnlyOneOption.value) {
        addExtraGuaranteeProduct(props.product.variant.sku);
      } else {
        emit('clickRemove');
        setIsGuaranteeActionBeingClicked(true);
        toggleCartSidebar();
        toggleExtraGuarantee();
      }
    };

    return {
      addExtraGuaranteeProduct,
      title,
      shouldDisplayComponent,
      handleExtraGuaranteeClick,
    };
  },
});

