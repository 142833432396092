






























import { defineComponent, computed, ref } from '@nuxtjs/composition-api';
import { DiscountCodeState } from '@vsf-enterprise/commercetools-types';
import { useCartExtended } from '~/composables';
import PrimaryInput from '~/components/atoms/Inputs/PrimaryInput/PrimaryInput.vue';
import { CUSTOM_QUERIES } from '~/constants/customQueries';
import { discountCodeExists } from '~/helpers/cart/cartDiscountGetter';
import { ButtonTextSize } from '~/types/components/Buttons/ButtonTextSize';
import Button from '~/components/atoms/Buttons/Button/Button.vue';

export default defineComponent({
  name: 'CartCoupon',
  components: {
    PrimaryInput,
    Button,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      cart,
      applyCoupon,
      removeCoupon,
      error: cartError,
    } = useCartExtended();

    const promoCode = ref('');
    const isCodeDuplicated = computed(() => discountCodeExists(cart.value, promoCode.value));
    const isCouponDoesNotMatchCartError = ref<boolean>(false);
    const isCouponEmpty = ref<boolean>(false);

    const couponErrorMessage = computed(() => {
      if (isCodeDuplicated.value) {
        return 'This voucher code is already added to your basket.';
      } else if (isCouponEmpty.value || cartError.value.applyCoupon) {
        return 'Invalid promo code';
      } else if (isCouponDoesNotMatchCartError.value) {
        return 'Coupon could not be applied';
      }
    });

    const submitCoupon = async (couponCode: string) => {
      if (!couponCode.length) {
        isCouponEmpty.value = true;
        return;
      }
      isCouponEmpty.value = false;
      await applyCoupon({
        couponCode,
        customQuery: CUSTOM_QUERIES.UPDATE_CART_CUSTOM,
      });
      const couponCodeNotMatchingCart = cart.value?.discountCodes?.find(
        (code) => code.state === DiscountCodeState.DoesNotMatchCart,
      )?.discountCode?.code;

      if (couponCodeNotMatchingCart) {
        await removeCoupon({
          couponCode: couponCodeNotMatchingCart,
          customQuery: CUSTOM_QUERIES.UPDATE_CART_CUSTOM,
        });
        isCouponDoesNotMatchCartError.value = true;
      }

      if (!cartError.value.applyCoupon) {
        promoCode.value = '';
      }
    };

    return {
      promoCode,
      submitCoupon,
      couponErrorMessage,
      isCodeDuplicated,
      isCouponDoesNotMatchCartError,
      cartError,
      ButtonTextSize,
      isCouponEmpty,
    };
  },
});
